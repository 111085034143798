import React from 'react';
import { Divider } from '@components/Grid';
import { graphql, Link } from 'gatsby';

import styles from './styles.module.scss';
import Layout from '@components/Layout';
import SEO from '@components/Seo';
import Hero from '@views/case-studies/lit-vodka/Hero';
import ProjectOverview from '@views/case-studies/ProjectOverview';
import Description from '@views/case-studies/Description';
import Branding from '@views/case-studies/lit-vodka/Branding';
import ReadMore from '@views/case-studies/ReadMore';

const LitVodka = ({ data }) => {
    return (
        <Layout>
            <SEO
                title="Lit Vodka • Case Studies"
                image={data?.gundiesPreview?.publicURL}
                description="Assisting an emerging distillery that has a unique audience base establish a digital presence."
            />
            <div className={styles.caseStudyWrapper}>
                <Hero image={data?.hero?.childImageSharp?.fluid} />
                <ProjectOverview
                    client="Lit Vodka"
                    name="Website"
                    backgroundColor="#2953A5"
                    description={
                        <p>
                            We were tasked to build a new expressive website which complements Lit
                            Vodka's existing brand.
                        </p>
                    }
                />

                <Divider margin={5} />
                <Divider xsMargin={0} mdMargin={5} />

                <Description
                    textLeft={
                        ' Lit Vodka is equipped with a contemporary website, with a focus on mobile responsiveness, the primary way to communicate with their target market.'
                    }
                    textRight={
                        <div>
                            <p>
                                Lit Vodka required a professional opinion on their website. We
                                diagnosed several issues with the help of{' '}
                                <Link to="#glossary=Lighthouse">Lighthouse</Link>. Load-times were
                                undesirable, accessibility was affected by clashing colour schemes
                                and key functionality did not work.
                            </p>

                            <p>
                                Their site was required as an informative support for their social
                                media campaign. We included as social media feed that updated
                                automatically with their instagram, as well as filling their site
                                with ultra-converting photography.
                            </p>
                        </div>
                    }
                />

                <Branding
                    drinks={data?.brandingDrinks?.childImageSharp?.fluid}
                    siteImage={data?.brandingSite?.childImageSharp?.fluid}
                    phonesImage={data?.brandingPhones?.childImageSharp?.fluid}
                    cocktail={data?.brandingCocktail?.childImageSharp?.fluid}
                />
                <ReadMore />

            </div>
        </Layout>
    );
};

export default LitVodka;

export const query = graphql`
    query {
        gundiesPreview: file(absolutePath: { regex: "/case-studies-the-gundies-preview.png/" }) {
            publicURL
        }
        hero: file(absolutePath: { regex: "/case-studies-lit-vodka-hero.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingDrinks: file(
            absolutePath: { regex: "/case-studies-lit-vodka-branding-drinks.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingSite: file(absolutePath: { regex: "/case-studies-lit-vodka-branding-site.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingPhones: file(
            absolutePath: { regex: "/case-studies-lit-vodka-branding-phones.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }        
        brandingCocktail: file(
            absolutePath: { regex: "/case-studies-lit-vodka-branding-cocktail.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
