import React from 'react';
import Img from 'gatsby-image';

import styles from './styles.module.scss';

const Hero = ({ image }) => {
    return (
        <section className={styles.heroWrapper}>
            <Img fluid={image} imgStyle={{ objectFit: 'cover' }} />
            <h1>Lit Vodka</h1>
            <p>
                Assisting an emerging distillery that has a unique audience base establish a digital
                presence.
            </p>
        </section>
    );
};

export default Hero;
