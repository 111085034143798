import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { Col, Divider, Grid, Row } from '@components/Grid';

import ScrollTextWithImage from '@components/ScrollTextWithImage';
import Button from '@components/Button';
import KnowMoreCta from '@components/CaseStudies/KnowMoreCta';
import styles from './styles.module.scss';

const Branding = ({ drinks, siteImage, phonesImage, cocktail }) => {
    return (
        <section className={styles.brandingWrapper}>
            <Img fluid={drinks} imgStyle={{ objectFit: 'cover', mixBlendMode: 'darken' }} />

            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <KnowMoreCta
                text={
                    <h2>
                        Want to us to audid your site? <br /> Contact us today.
                    </h2>
                }
                link="tel:01206259355"
                buttonText="Phone us"
            />

            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <Grid>
                <Row>
                    <Col xs={12}>
                        <div className={styles.brandingHeadingWrapper}>
                            <div className={styles.brandingHeading}>
                                <h2>
                                    Their drink now has the presence to contest with leading
                                    distilleries.
                                </h2>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Divider xsMargin={0} mdMargin={5} />
                <Divider xsMargin={0} mdMargin={5} />

                <ScrollTextWithImage imageFluid={siteImage} imageLeft>
                    <Divider xsMargin={5} mdMargin={0} />
                    <h2>Built in a modern environment for speed, attention & conversion.</h2>
                    <p>
                        We chose to take their popular saying "Live In Today" and displayed it as a
                        moving banner across their blue tinted image.
                    </p>
                    <p>
                        We designed two different designs and one of them was chosen by the client.
                    </p>

                    <p>If you require our web design services click the button below.</p>
                    <Link to="/service/web-platforms/web-design">
                        <Button text="Read more" />
                    </Link>
                </ScrollTextWithImage>

                <Divider margin={5} />
                <Divider xsMargin={0} mdMargin={5} />

                <Row>
                    <Col xs={12}>
                        <div className={styles.brandingHeadingWrapper}>
                            <div className={styles.brandingHeading}>
                                <h2>
                                    Their website is built on a modern platform ready for the 2020s.
                                </h2>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Grid>

            <Divider margin={5} />

            <Img fluid={phonesImage} imgStyle={{ objectFit: 'cover' }} />

            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <Grid>
                <ScrollTextWithImage imageFluid={cocktail}>
                    <Divider xsMargin={5} mdMargin={0} />
                    <p>
                        Their website was designed mobile-first to ensure the target market had the
                        most optimum experience.
                    </p>
                    <p>
                        Our site outperformed the competition Lighthouse tests for speed and
                        accessibility.
                    </p>

                    <p>Want to know more about our web-development methodology?</p>
                    <Link to="/service/web-platforms/web-development">
                        <Button text="Read more" />
                    </Link>
                </ScrollTextWithImage>
            </Grid>
        </section>
    );
};

export default Branding;
